import { domReady, qs, qsa, removeAttribute } from 'lib/dom.js'
import { currency, delimiter, language, separator } from 'lib/environment.js'
import { dispatch, on, stop } from 'lib/events.js'

const currencyFormatter = new Intl.NumberFormat(language, { style: 'currency', currency: currency })
const currencyKeydownRegex = new RegExp(`^[\\d\\${ delimiter }\\${ separator }]$`)
const currencyPasteRegex = new RegExp(`^[\\d\\${ delimiter }\\${ separator }]*$`)
const currencyStripDelimiterRegex = new RegExp(delimiter, 'g')

function abandonComponentHandler({ target }, selector = null) {
	if (target === document) {
		return true
	}

	const container = target.closest('.input-component')
	let abandon = target.nodeName !== 'INPUT' || target.type === 'checkbox' || target.type === 'radio' || container === null

	if (!abandon && selector !== null) {
		abandon = !target.matches(selector)
	}

	if (!abandon && !('inputComponentContainer' in target)) {
		target.inputComponentContainer = container
	}

	return abandon
}

function maskedKeydownHandler(event) {
	if (abandonComponentHandler(event, 'input[data-mask]')) {
		return
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('#maskedKeydownHandler', event)
	}

	if (event.key === 'Backspace') {
		event.target.dataset.skipNextMaskApplication = true
	}
}

function maskValue(mask, value) {
	if (value === '') {
		return value
	}

	let masked = ''
	let characters = value.split('')

	for (let i = 0; i < mask.length; i++) {
		let maskCharacter = mask.charAt(i)

		if (maskCharacter !== '_') {
			masked += maskCharacter
		} else if (maskCharacter === '_' && characters.length > 0) {
			masked += characters.shift()
		} else {
			break
		}
	}

	return masked
}

function maskPosition(start, mask) {
	let previous

	for (let i = (start - 1); i > 0; i--) {
		if (mask.charAt(i) === '_') {
			previous = i

			break
		}
	}

	// This will be undefined if the first character of the mask is a literal
	// character i.e. not _ and the input happened starting at the 0 position.
	if (previous === undefined) {
		previous = mask.indexOf('_')
	}

	let position

	for (let i = (previous + 1); i < mask.length; i++) {
		if (mask.charAt(i) === '_') {
			position = i

			break
		}
	}

	// If there's no further to go in the mask and the next position is
	// undefined, then we're just at the end.
	if (position === undefined) {
		position = mask.length
	}

	return position
}

// Apply a mask over the input, after an allowable value has been inserted.
// Cribbed, in part, from https://stackoverflow.com/a/35305832.
function maskedInputHandler(event) {
	if (abandonComponentHandler(event, 'input[data-mask]')) {
		return
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('#maskedInputHandler', event)
	}

	const target = event.target
	const mask = target.dataset.mask
	const unmaskedValue = target.value.replace(new RegExp(target.dataset.unmaskPattern, 'g'), '')

	addUnmaskedHiddenInput(target)

	if (target.dataset.sendMask === undefined) {
		qs(target.dataset.unmaskedTarget).value = unmaskedValue
	}

	/*
	 * This happens after we set the unmasked value so that if the entire value of
	 * the input is wiped out the unmasked input is kept in sync.
	 */
	if (target.dataset.skipNextMaskApplication === 'true') {
		delete target.dataset.skipNextMaskApplication

		return
	}

	const maskedValue = maskValue(mask, unmaskedValue)

	/*
	 * selectionStart is where the cursor is _after_ the input has happened, so
	 * the native position. We want the appropriate position in the mask, based on
	 * where they _were_.
	 */
	const position = maskPosition(target.selectionStart, mask)

	target.value = maskedValue
	target.setSelectionRange(position, position)
}

function currencyKeydownHandler(event) {
	if (abandonComponentHandler(event, 'input[data-restrict="currency"]')) {
		return
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('#currencyKeydownHandler', event)
	}

	const key = event.key || ''

	if (!event.ctrlKey && !event.altKey && !event.metaKey && key.length === 1) {
		// Not a number, delimiter, or separator.
		if (!currencyKeydownRegex.test(key)) {
			return stop(event)
		}

		const input = event.target
		const value = input.value

		const separatorIndex = value.indexOf(separator)

		/*
		 * If the input is empty and the key pressed is the delimiter or separator
		 * just stop, that's not a valid number.
		 */
		if (value === '' && (key === separator || key === delimiter)) {
			return stop(event)

		// There's already a separator, which means things get complicated.
		} else if (separatorIndex > -1) {
			if (key === separator) {
				return stop(event)
			}

			const parts = value.split(separator)
			const fractionLength = parts[1].length

			// The fraction length should likely be configurable, but that's a problem
			// for another day.
			if (input.selectionStart > separatorIndex && fractionLength === 2) {
				return stop(event)
			}
		}
	}
}

function currencyPasteHandler(event) {
	if (abandonComponentHandler(event, 'input[data-restrict="currency"]')) {
		return
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('#currencyPasteHandler', event)
	}

	const pastedContent = event.clipboardData.getData('text')

	if (!currencyPasteRegex.test(pastedContent)) {
		stop(event)
	}
}

function currencyInputHandler(event) {
	if (abandonComponentHandler(event, 'input[data-restrict="currency"]')) {
		return
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('#currencyInputHandler', event)
	}

	const input = event.target
	const value = input.value

	addUnmaskedHiddenInput(input)

	const unmaskedTarget = qs(input.dataset.unmaskedTarget)

	if (value === '') {
		unmaskedTarget.value = ''
		dispatch(unmaskedTarget, 'input')

		input.inputComponentCharacterCount = 0
		input.inputComponentFormattingCharactersCount = 0

		return
	}

	const inputType = event.inputType
	const cursorPosition = input.selectionStart
	const unmaskedValue = value.replace(currencyStripDelimiterRegex, '')

	/*
	 * substr(1) is used here to lop the currency symbol off the front of the string.
	 */
	const formattedValue = currencyFormatter.format(Number(unmaskedValue)).substring(1)
	const charactersAddedByFormatting = formattedValue.length - formattedValue.replace(currencyStripDelimiterRegex, '').length

	if (input.inputComponentCharacterCount === undefined) {
		input.inputComponentCharacterCount = unmaskedValue.length
	}

	if (input.inputComponentFormattingCharactersCount === undefined) {
		input.inputComponentFormattingCharactersCount = charactersAddedByFormatting
	}

	switch(inputType) {
		case undefined:
			/*
			 * An undefined inputType can happen when an input event is triggered via
			 * dispatchEvent and in the case of simple even numbers like "5" they
			 * often get misformatted as "5.0" rather than "5.00" because that's the
			 * value output by BigDecimal#to_s('F').
			 */
			if (input.inputComponentCharacterCount === unmaskedValue.length) {
				input.inputComponentCharacterCount = formattedValue.length
			}

			break
		case 'insertFromPaste':
			input.inputComponentCharacterCount = unmaskedValue.length
			input.inputComponentFormattingCharactersCount = charactersAddedByFormatting

			break
		case 'insertText':
			/*
			 * If the unmasked value is only one character long but our character
			 * count is larger, then reset the character count. This can happen when
			 * the entire input is selected and then replaced with a single value
			 * keystroke i.e. C-a 5.
			 */
			if (unmaskedValue.length === 1 && input.inputComponentCharacterCount > 1) {
				input.inputComponentCharacterCount = 1
				input.inputComponentFormattingCharactersCount = 0
			}

			/*
			 * Increment the inserted character count unless this is our first time
			 * through, in which case both the length and character count will be one,
			 * which is correct.
			 */
			if (!(unmaskedValue.length === 1 && input.inputComponentCharacterCount === 1)) {
				input.inputComponentCharacterCount++
			}

			break
		case 'deleteContent':
		case 'deleteContentBackward':
		case 'deleteContentForward':
			/*
			 * Reset the inserted character count to the number of unmasked characters.
			 */
			input.inputComponentCharacterCount = unmaskedValue.length
			break
	}

	unmaskedTarget.value = unmaskedValue
	dispatch(unmaskedTarget, 'input')

	input.value = formattedValue.substring(0, input.inputComponentCharacterCount + charactersAddedByFormatting)

	/*
	 * We've added or removed a formatting character, so we need to treat the difference as an offset.
	 */
	if (input.inputComponentFormattingCharactersCount !== charactersAddedByFormatting) {
		if (input.inputComponentFormattingCharactersCount > charactersAddedByFormatting) {
			/* We've lost a formatting character, so we need to shift left. */
			input.setSelectionRange(cursorPosition - (input.inputComponentFormattingCharactersCount - charactersAddedByFormatting), cursorPosition - (input.inputComponentFormattingCharactersCount - charactersAddedByFormatting))
		} else {
			/* We've gained a formatting character so we need to shift right. */
			input.setSelectionRange(cursorPosition + (charactersAddedByFormatting - input.inputComponentFormattingCharactersCount), cursorPosition + (charactersAddedByFormatting - input.inputComponentFormattingCharactersCount))
		}
	} else {
		input.setSelectionRange(cursorPosition, cursorPosition)
	}

	input.inputComponentFormattingCharactersCount = charactersAddedByFormatting
}

function numericKeydownHandler(event) {
	if (abandonComponentHandler(event, 'input[data-restrict="numeric"]')) {
		return
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('#numericKeydownHandler', event)
	}

	const key = event.key || ''

	if (!event.ctrlKey && !event.altKey && !event.metaKey && key.length === 1 && !/^\d$/.test(key)) {
		stop(event)
	}
}

function numericPasteHandler(event) {
	if (abandonComponentHandler(event, 'input[data-restrict="numeric"]')) {
		return
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('#numericPasteHandler', event)
	}

	const pastedContent = event.clipboardData.getData('text')
	const numericContent = pastedContent.replace(/\D/g, '')

	if (pastedContent !== numericContent) {
		stop(event)

		const input = event.target
		const cursorStart = input.selectionStart
		const cursorEnd = input.selectionEnd
		const value = input.value
		const prefix = value.substring(0, cursorStart)
		const suffix	= value.substring(cursorEnd, value.length)

		input.value = `${ prefix }${ numericContent }${ suffix }`
		dispatch(input, 'input')

		if (cursorStart === 0) {
			input.setSelectionRange(input.value.length, input.value.length)
		}
	}
}

function minimumValueHandler(event) {
	if (abandonComponentHandler(event, 'input[data-min]:not([data-max])')) {
		return
	}

	const input = event.target

	if (Number(input.value) < Number(input.dataset.min)) {
		input.setCustomValidity('invalid')
	} else {
		input.setCustomValidity('')
	}
}

function maximumValueHandler(event) {
	if (abandonComponentHandler(event, 'input[data-max]:not([data-min])')) {
		return
	}

	const input = event.target

	if (process.env.NODE_ENV === 'development') {
		console.log('#maximumValueHandler', event, { max: Number(input.dataset.max), value: Number(input.value.replace(currencyStripDelimiterRegex, '')) })
	}

	if (Number(input.value.replace(currencyStripDelimiterRegex, '')) > Number(input.dataset.max)) {
		input.setCustomValidity('invalid')
	} else {
		input.setCustomValidity('')
	}
}

function minimumMaximumValueHandler(event) {
	if (abandonComponentHandler(event, 'input[data-max][data-min]')) {
		return
	}

	const input = event.target

	if (Number(input.value) > Number(input.dataset.max) || Number(input.value) < Number(input.dataset.min)) {
		input.setCustomValidity('invalid')
	} else {
		input.setCustomValidity('')
	}
}

function addUnmaskedHiddenInput(element) {
	if (element !== document && element.dataset.unmaskedTarget === undefined && element.dataset.sendMask === undefined) {
		const name = element.name
		const id = `unmaskedInput-${ Math.floor(Math.random() * 100000) }`

		element.dataset.unmaskedTarget = `#${ id }`
		element.insertAdjacentHTML('afterend', `<input type="hidden" name="${ name }" id="${ id }">`)
		removeAttribute(element, 'name')
	}
}

function updateValidationClasses(event) {
	if (abandonComponentHandler(event)) {
		return
	}

	const target = event.target
	const container = target.inputComponentContainer
	const inputType = event.inputType

	// See https://rawgit.com/w3c/input-events/v1/index.html#interface-InputEvent-Attributes for more inputType values.
	let hasInput = target.value !== '' || inputType === 'insertReplacementText'

	/*
	 * Autofilled inputs are kinda weird, so we match on the CSS selector and
	 * assume there's a value.
	 */
	if (!hasInput) {
		try {
			hasInput = target.matches('input:-internal-autofill-selected')
		} catch { } // eslint-disable-line no-empty
	}

	if (!hasInput) {
		try {
			hasInput = target.matches('input:-webkit-autofill')
		} catch { } // eslint-disable-line no-empty
	}

	container.classList.toggle('has-input', hasInput)
	container.classList.toggle('invalid', !target.validity.valid)
	container.classList.toggle('valid', target.validity.valid)
}

function splitValueInputHandler(event) {
	if (abandonComponentHandler(event, 'input[data-split-delimiter][data-split-names]')) {
		return
	}

	if (process.env.NODE_ENV === 'development') {
		console.log('#splitValueInputHandler', event)
	}

	const input = event.target
	const value = input.value

	if (value === '') {
		return
	}

	const container = input.inputComponentContainer
	const names = input.dataset.splitNames.split(' ')

	if (!('inputComponentSplitInputs' in input)) {
		names.forEach(name => input.insertAdjacentHTML('afterend', `<input type="hidden" name="${ name }">`))
		input.inputComponentSplitInputs = names.map(name => qs(`input[name="${ name }"]`, container))
	}

	value.split(input.dataset.splitDelimiter).forEach((val, index) => {
		input.inputComponentSplitInputs[index].value = val
	})
}

/*
 * Validate that the DOB put into the input is at least the minimum age. This
 * currently assumes the US date format MM/DD/YYYY.
 */
function minimumAgeInputHandler(event) {
	if (abandonComponentHandler(event, 'input[data-minage]')) {
		return
	}

	const input = event.target
	const value = input.value

	/*
	 * Don't bother trying to validate the date if the input's not filled in all
	 * the way.
	 */
	if (value.length === input.maxLength) {
		const minAge = Number(input.dataset.minage)

		const today = new Date()
		const targetDate = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())

		const [month, day, year] = value.split(input.dataset.splitDelimiter).map(str => Number(str))
		const inputDate = new Date(year, month - 1, day)

		if (inputDate <= targetDate) {
			input.setCustomValidity('')
		} else {
			input.setCustomValidity('invalid')
		}

		dispatch(input, 'validate')
	}
}

// Update validation classes
on(document, 'change, input, validate', updateValidationClasses, { passive: true })

// Numeric
on(document, 'paste', numericPasteHandler)
on(document, 'keydown', numericKeydownHandler)

// Currency
on(document, 'keydown', currencyKeydownHandler)
on(document, 'paste', currencyPasteHandler)
on(document, 'input', currencyInputHandler, { passive: true })

// Masked
on(document, 'keydown', maskedKeydownHandler, { passive: true })
on(document, 'input', maskedInputHandler, { passive: true })

// Min/max
on(document, 'input', minimumValueHandler, { passive: true })
on(document, 'input', maximumValueHandler, { passive: true })
on(document, 'input', minimumMaximumValueHandler, { passive: true })

// Split inputs
on(document, 'input', splitValueInputHandler, { passive: true })

// Minimum age inputs
on(document, 'input', minimumAgeInputHandler, { passive: true })

// Simple focusin class business
on(document, 'focusin', event => {
	if (abandonComponentHandler(event)) {
		return
	}

	event.target.inputComponentContainer.classList.add('focus-within')
}, { passive: true })

// Simple focusout class business
on(document, 'focusout', event => {
	if (abandonComponentHandler(event)) {
		return
	}

	/* We dispatch on focusout to help catch autocompleted values. */
	dispatch(event.target, 'validate')
	event.target.inputComponentContainer.classList.remove('focus-within')
}, { passive: true })

// Revealable button clicks
on(document, 'click', ({ target }) => {
	if (target.nodeName === 'BUTTON' && target.dataset.toggle === 'reveal') {
		const input = qs(target.dataset.target)

		input.type = input.type === 'password' ? 'text' : 'password'
	}
}, { passive: true })

domReady(_ => {
	/*
	 * Inputs with values should be validated when the DOM loads, espcially if
	 * they have a mask.
	 */
	qsa('.input-component.has-input').forEach(container => {
		dispatch(qs('input', container), 'input')
	})

	/*
	 * Inputs with autofocus do not trigger the focusin event, so we check to see
	 * if there's an active element when the DOM is loaded and try to initialize
	 * it.
	 */
	if (document.activeElement && document.activeElement.matches('.input-component input')) {
		const activeElement = document.activeElement

		if (process.env.NODE_ENV === 'development') {
			console.log('#activeElement', activeElement)
		}

		/*
		 * Safari is doing something really weird and focusing a non-autofocus
		 * element on page load. To get around that, we check for the autofocus
		 * attribute and, if absent, blur the active element and try to focus the
		 * first valid autofocusable input component.
		 */
		if (activeElement.matches('[autofocus]')) {
			dispatch(activeElement, 'focusin')
		} else {
			dispatch(activeElement, 'focusout')
			activeElement.blur()

			const autofocus = qs('.input-component input[autofocus]')

			if (autofocus) {
				dispatch(autofocus, 'focusin')
				autofocus.focus()
			}
		}
	}

	/*
	 * Initialize autofilled form fields if possible. The delay here is to account
	 * for the time it takes Chrome to fill in the values. This is run inside a
	 * try/catch because browsers that don't support this selector will throw an
	 * error.
	 */
	setTimeout(_ => {
		try {
			qsa('input:-internal-autofill-selected').forEach(input => {
				// Check for a container in case this isn't an input component.
				if (input.closest('.input-component')) {
					dispatch(input, 'input')
				}
			})
		} catch { } // eslint-disable-line no-empty
	}, 550)

	setTimeout(_ => {
		try {
			qsa('input:-webkit-autofill').forEach(input => {
				// Check for a container in case this isn't an input component.
				if (input.closest('.input-component')) {
					dispatch(input, 'input')
				}
			})
		} catch { } // eslint-disable-line no-empty
	}, 550)
})
